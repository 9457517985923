import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import Layout from '../../components/Layout'
import OffersComponent from '../../components/OffersComponent'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

export const query = graphql`
  query EnOfferQuery {
    allContentXlsxEnOffer {
      nodes {
        team
        content
      }
    }
  }
`

interface Offer {
  team: string
  content: string
}

interface Nodes {
  nodes: Array<Offer>
}

interface DataSheet {
  allContentXlsxEnOffer: Nodes
}

export interface Props {
  data: DataSheet
}
const Offer = ({
  data: {
    allContentXlsxEnOffer: { nodes: offers }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout isEn actualTab="Offer">
      <p className={styles.title}>Offers</p>
      <OffersComponent offers={offers} />
    </Layout>
  )
}

export default Offer
